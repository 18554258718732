import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'  
import { getToken } from '@/utils/auth' // get token from cookie
 
import Home from '@/views/Home' 
import infoRouters from './info' 

Vue.use(VueRouter)

// 基础页面路由，不需要权限控制也能访问
export const baseRoutes = [ 
  ...infoRouters, 
  {
    path: '',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    },
    hidden: true,
  }, 
  
]

// 需要权限控制的页面路由
export const authRoutes = [
 
]

const routerConfig = {
  mode: 'history',
  base: process.env.NODE_ENV === 'development' ? '' : '',
  routes: baseRoutes,  
}
const router = new VueRouter(routerConfig)

const loginUrlArray = ['/home'];
const whiteList = ['/home'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  const title = to.query.navbarTitle || to.meta.title
  if (title) {
    // 设置 navTab 标题
    to.meta.title = title 
  }

  next()
  return
  // 白名单内不用校验
  if (whiteList.indexOf(to.path) !== -1) { 
    next()
    return
  }
  
  // const token = getToken()
  // if (token) {
  //   if (loginUrlArray.indexOf(to.path) !== -1) {
  //     next({ path: '/' })
  //   } else {
  //     const hasRoles = store.getters.permissions
  //     // 判断权限是否存在
  //     if (hasRoles) {
  //       next()
  //     } else {
  //       try {
  //         // 获取用户信息及用户权限
  //         await store.dispatch('user/getInfo') 
  //         await store.dispatch('user/getParameters')
  //         const accessRoutes = await store.dispatch('permission/generateRoutes', ['admin'])
  //         // debugger
  //         router.addRoutes(accessRoutes) 
  //         next({ ...to, replace: true })
  //       } catch (error) {
  //         // 移除token并退出登录
  //         await store.dispatch('user/resetToken') 
  //       }
  //     }
  //   }
  // } else {
  // }
  next()
})

// 替换现有 router 的 routes
export function resetRouter() {
  router.matcher = new VueRouter(routerConfig).matcher
}

export default router