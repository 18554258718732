import { post, signPost } from "@/api/apibase.js";

export function apiMobileCode(data) {
  return signPost("client/auth/mobileCode/v1", data);
}
export function apiApply(data) {
  return post("client/Clue/apply/v1", data);
}

export function apiClueCreate(data) {
  return post("client/Clue/create/v1", data);
}

export function apiClueMatching(data) {
  return post("client/Clue/matching/v1", data);
}

export function apiClueAdvertising(data) {
  return post("client/Clue/advertising", data);
}

export function apiUv(data) {
  return post("client/index/i", data);
}

export function apiClueSubmitApplications(data) {
  return post("/client/Clue/submitApplications/v1", data);
}

export function apiIndexAdvertising(data) {
  return post("/client/Index/advertising", data);
}

export function apiIndexadvertisingUrl(data) {
  return post("/client/Clue/advertisingUrl", data);
}
export function apiClueInfo(data) {
  return post("/client/Clue/info", data);
}



export function spClueinfo(data) {
  return post("/channel/spClueinfo", data);
}

export function spSubmission(data) {
  return post("/channel/spSubmission", data);
}



