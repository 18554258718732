import * as dd from "dingtalk-jsapi";

/**
 * 参数转成对象
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

/**
 * 判断是否是微信浏览器
 * @returns {boolean}
 */
export const isWeiXin = () => {
  let check = navigator.userAgent.toLowerCase().indexOf("micromessenger");
  return check !== -1 ? true : false;
};

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = (url) => {
  let paramObj = {};
  if (url.indexOf("?") != -1) {
    const keyValueArr = url.split("?")[1].split("&");
    keyValueArr.forEach((item) => {
      const keyValue = item.split("=");
      paramObj[keyValue[0]] = keyValue[1];
    });
  }
  return paramObj;
};

/**
 * 深拷贝
 * @param source
 * @returns {[]|{}}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * 国际时间转年月日时分秒
 * @param source
 * @returns String
 */
export function getymd(date) {
  var date = new Date(date);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  return (
    year +
    "-" +
    formatTen(month) +
    "-" +
    formatTen(day) +
    " " +
    formatTen(hour) +
    ":" +
    formatTen(minute) +
    ":" +
    formatTen(second)
  );
}

export function dateFormat(longTypeDate, fmt) {
  var date =
    longTypeDate instanceof Date ? longTypeDate : new Date(longTypeDate);
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

/**
 * 获取当前年月日时分秒
 */
export function formatDate() {
  let time = new Date();
  // 获取年
  let year = time.getFullYear();
  // 获取月
  let month = time.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  // 获取日
  let date = time.getDate();
  date = date < 10 ? "0" + date : date;
  // 获取时
  let hours = time.getHours();
  hours = hours < 10 ? "0" + hours : hours;
  // 获取分
  let minutes = time.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  // 获取秒
  let second = time.getSeconds();
  second = second < 10 ? "0" + second : second;
  return (
    year + "/" + month + "/" + date + " " + hours + ":" + minutes + ":" + second
  );
}

/**
 * 加法
 */
export const addSub = (arg1, arg2) => {
  (arg1 = arg1.toString()), (arg2 = arg2.toString()); // 将传入的数据转化为字符串
  var arg1Arr = arg1.split("."), // 将小数的数据从小数点的位置拆开
    arg2Arr = arg2.split("."),
    d1 = arg1Arr.length == 2 ? arg1Arr[1] : "", // 获取第一个数的小数点的长度
    d2 = arg2Arr.length == 2 ? arg2Arr[1] : ""; // 获取第二个数的小数点的长度
  var maxLen = Math.max(d1.length, d2.length); // 获取小数点长度较大的值
  var m = Math.pow(10, maxLen); // 这里表示10的小数点长度次方 也就是说如果小数点长度为2 m的值就是100 如果小数点长度是3 m的值就是1000如果不懂请自行查找api
  var result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen)); // 将小数转化为整数后相加在除掉两个数乘过的倍数然后去小数点较长的长度的小数位数
  return typeof d === "number" ? Number(result) : result;
};

/**
 * 时间不满十进0
 * @param source
 * @returns String
 */
function formatTen(num) {
  return num > 9 ? num + "" : "0" + num;
}

/**
 * 计算两个时间之间的时间差 （分）
 * time1: 开始时间
 * time2：结束时间
 */
export function TimeDifference(time1, time2) {
  if (time1 > time2) {
    return "";
  }
  //截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
  var begin1 = time1.substr(0, 10).split("-");
  var end1 = time2.substr(0, 10).split("-");
  //将拆分的数组重新组合，并实例成化新的日期对象
  var date1 = new Date(begin1[1] + -+begin1[2] + -+begin1[0]);
  var date2 = new Date(end1[1] + -+end1[2] + -+end1[0]);
  //得到两个日期之间的差值m，以分钟为单位
  //Math.abs(date2-date1)计算出以毫秒为单位的差值
  //Math.abs(date2-date1)/1000得到以秒为单位的差值
  //Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
  var m = parseInt(Math.abs(date2 - date1) / 1000 / 60);
  //小时数和分钟数相加得到总的分钟数
  //time1.substr(11,2)截取字符串得到时间的小时数
  //parseInt(time1.substr(11,2))*60把小时数转化成为分钟
  var min1 = parseInt(time1.substr(11, 2)) * 60 + parseInt(time1.substr(14, 2));
  var min2 = parseInt(time2.substr(11, 2)) * 60 + parseInt(time2.substr(14, 2));
  //两个分钟数相减得到时间部分的差值，以分钟为单位
  var n = min2 - min1;
  //将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
  var minutes = m + n;
  return minutes;
}

/**
 * 获取url上的参数
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

export const str2obj = (str) => {
  let obj;
  try {
    obj = JSON.parse(str);
  } catch (error) {
    return obj;
  }
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      if (typeof element == "string" && /^\{.*\}$|^\[.*\]$/.test(element)) {
        obj[key] = str2obj(`${element}`);
      }
    }
  }
  return obj;
};

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * 获取当前年月日时分秒
 * */
export const getAllCurrentTime = () => {
  var newDate = new Date(); //获取今天日期
  let newYear = newDate.getFullYear(),
    newMonth = newDate.getMonth() + 1,
    newDay = newDate.getDate(),
    newHouse = newDate.getHours(),
    newMin = newDate.getMinutes(),
    newSeco = newDate.getSeconds();
  (newMonth = newMonth >= 10 ? newMonth : "0" + newMonth),
    (newDay = newDay >= 10 ? newDay : "0" + newDay);
  let newTime =
    newYear +
    "-" +
    newMonth +
    "-" +
    newDay +
    " " +
    newHouse +
    ":" +
    newMin +
    ":" +
    newSeco;
  return newTime;
};

/**
 * 判断是不需要显示 “融安贷”相关title
 * @return {boolean}
 */
export const isShowTitle = () => {
  const whiteList = ["ztkrad.hfhjybf.cn"];
  let url = window.location.hostname;
  //console.log("域名：", url);
  let falge = whiteList.includes(url);
  falge ? (document.title = " ") : (document.title = "融安贷");

  return falge ? false : true;
};

/**
 * 身份证校验
 * @param {*} 身份证号码
 * @returns
 */
export const isValidChineseID = (id) => {
  if (!id || id.length !== 18) {
    return false;
  }

  const weight = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const checkCode = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
  let sum = 0;

  for (let i = 0; i < 17; i++) {
    const num = Number(id[i]);
    if (isNaN(num)) {
      return false;
    }
    sum += num * weight[i];
  }

  const last = id[17].toUpperCase();
  const index = last === "X" ? 10 : Number(last);

  return checkCode[sum % 11] === last || index === checkCode[sum % 11];
};
