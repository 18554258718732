import Vue from 'vue'
import Vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css'
import * as dd from 'dingtalk-jsapi'
import App from './App.vue'
import axios from 'axios'
import './registerServiceWorker'
import router from './router'
import store from './store' 
import './utils/dataFormat'
import 'xe-utils'  
import { Dialog, Notify, Toast,showLoadingToast} from 'vant'
import '@/assets/font/font.css'
import 'amfe-flexible'
import VConsole from 'vconsole'
if(process.env.NODE_ENV === '.development') {
  new VConsole()
} 

// url 请求路径
// Vue.prototype.$url = 'http://npjwa.6o3.cn'
Vue.prototype.$url = 'http://192.168.1.252:323/'
// Vue.prototype.$url ='http://192.168.1.94/'
Vue.prototype.$axios = axios
   
Vue.prototype.$el = '' // 用于设置 scrollTop 

Vue.prototype.regexp = {
  phone: /1\d{10}/,
  intNum: /^[1-9]\d*$/,
}
Vue.prototype.$minDate = new Date()
Vue.prototype.$maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)) 
Vue.prototype.$dialog = Dialog
Vue.prototype.$notify = Notify
Vue.prototype.$toast = Toast 
Vue.prototype.$showLoadingToast = showLoadingToast 


// 引入全局可用阿里图标样式文件
import './assets/iconfont/iconfont.css'
// 引入全局可用阿里图标js文件
import './assets/iconfont/iconfont.js'
// 引入自己设置的全局icon基础样式，一般用于规定基础的svg宽高和图标大小
import './assets/css/iconfont.css'


 
 
Vue.use(Vant) 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
