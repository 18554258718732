import { post, signPost } from "@/api/apibase.js";

export function apiConfigChannel(data) {
  return post("/client/Config/channel", data);
}

export function apiConfig(data) {
  return signPost("/client/Config/index", data);
}

export function apiConfigAgreement(data) {
  return post("/client/Config/agreement", data);
}

export function apiConfigCity(data) {
  return post("/client/Config/city", data);
}
