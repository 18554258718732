/**
   * 将字符串进行 Base64 编码
   * @param {string} input - 需要编码的字符串
   * @returns {string} - 编码后的 Base64 字符串
   * @throws {Error} - 如果输入参数无效，抛出错误
   */
export function base64_encode(input) {
    // 参数检查：确保输入为非空字符串
    if (typeof input !== 'string' || input.trim() === '') {
        throw new Error('输入必须是非空字符串');
    }

    try {
        return btoa(encodeURIComponent(input));
    } catch (error) {
        // 安全检查：捕获编码过程中可能的错误
        throw new Error('编码失败，请检查输入是否有效');
    }
}
/**
   * 将 Base64 字符串解码为原始字符串
   * @param {string} input - 需要解码的 Base64 字符串
   * @returns {string} - 解码后的原始字符串
   * @throws {Error} - 如果输入参数无效或解码失败，抛出错误
   */
export function base64_decode(input) {
    // 参数检查：确保输入为非空字符串且格式正确
    if (typeof input !== 'string' || input.trim() === '') {
        throw new Error('输入必须是非空的 Base64 字符串');
    }

    try {
        return decodeURIComponent(atob(input));
    } catch (error) {
        // 安全检查：捕获解码过程中可能的错误
        throw new Error('解码失败，请检查 Base64 字符串是否有效');
    }
}

