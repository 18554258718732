<template>
  <div>
    <van-nav-bar :style="{background: backgroundColor}">
      <template #left>
        <div @click="onClickLeft" :style="{ color: textColor, fontSize: '0.48rem' }">
          <van-icon name="arrow-left" :color="textColor"  size="0.48rem"  />
          <span>{{ title }}</span>
        </div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import { isShowTitle } from "@/utils/index.js";
export default {
  props: { 
    // 标题
    title: { 
      type: String,
      default: isShowTitle()? '融安贷' : ''
    },
    isBack: { 
      type: Boolean,
      default: true
    },
    // 字体颜色
    textColor: { 
      type: String,
      default: 'black'
    },
    // 背景颜色
    backgroundColor: { 
      type: String,
      default: 'white'
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    onClickLeft() { 
      this.$router.go(-1)
    }
  },
}
</script>