import { isShowTitle } from "@/utils/index.js";

export function getFinanceConfig() {
  return {
    companyName: "广州信睿科技有限公司", // 贷款公司名称
    companyMianName: "遂宁市船山区大诚小额贷款有限公司", // 贷款公司名称2
    icp: isShowTitle() ? "蜀ICP备2023029746号-1" : "渝ICP备2021011792号", // 备案号
    servicePhone: "rongandai@163.com", // 客服电话
  };
}

var pro_client_url = "https://apitbes.ybcm1000.com/";
var test_client_url = "https://gicemtwo.ybcm1000.com/";
var dev_client_url = "http://a.f.com/";
export function getBaseUrl(url) {
  const apiUrl = process.env.VUE_APP_BASE_API; // 从环境变量中获取 API URL
  // var NODE_ENV = process.env.NODE_ENV;
  // console.log(NODE_ENV, 'NODE_ENV')
  // var apiUrl = pro_client_url;;
  // if (NODE_ENV == "development") {
  //   //开发环境
  //   apiUrl = dev_client_url;
  // }
  console.log(apiUrl, "=======");
  return apiUrl + url;
}
