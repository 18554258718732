import Cookies from 'js-cookie'
import { headerToken } from '@/config/Settings.js'

export function getToken() {
  // return Cookies.get(headerToken[process.env.NODE_ENV])
}

export function setToken(token) {
  // return Cookies.set(
  //   headerToken[process.env.NODE_ENV],
  //   token,
  //   {
  //     expires: 365,
  //   }
  // )
}

export function removeToken() {
  // return Cookies.remove(
  //   headerToken[process.env.NODE_ENV], {
  //   }
  // )
}
