// import {
//   login, logout, getInfo, permissonGet 
// } from '@/user'

// /account/login  post
// /permisson/get  post
// /sso/logout   get
// /permisson/getUserInfo  get
 
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(), 
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
   
}

const actions = {
  //登录
  login({ commit }, userInfo) {
    let { password, phone } = userInfo
    phone = phone.trim().replace(/\s+/g, '')
    return new Promise((resolve, reject) => {
        
    })
  }, 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
