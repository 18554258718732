import axios from "axios";
import { getBaseUrl } from "@/config/Settings.js";
import { encrypt } from "@/utils/encryptUtil.js";

export function post(url, data = []) {
  console.log(url, "url");
  url = getBaseUrl(url);
  return axios.post(url, data);
}

export function signPost(url, data) {
  url = getBaseUrl(url);
  if (!data) {
    data = [];
  }
  data["time"] = Math.floor(Date.now() / 1000).toString();
  data = convertNumbersToStrings(data);
  var sign = encrypt(data);
  data["sign"] = sign;
  return axios.post(url, data);
}

function convertNumbersToStrings(data) {
  return Object.keys(data).reduce((acc, key) => {
    // 如果是数字，则转换为字符串，否则保留原值
    acc[key] = typeof data[key] === "number" ? String(data[key]) : data[key];
    return acc;
  }, {});
}
