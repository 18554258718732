export default [
  {
    path: '/info/writeInfo',
    name: 'writeInfo',
    component: () => import('@/views/info/writeInfo'),
    meta: {
      title: '填写资料'
    }
  },  
  {
    path: '/info/checkPass',
    name: 'checkPass',
    component: () => import('@/views/info/checkPass'),
    meta: {
      title: '审核通过'
    }
  }, 
  {
    path: '/info/applySumbit',
    name: 'applySumbit',
    component: () => import('@/views/info/applySumbit'),
    meta: {
      title: '申请提交'
    }
  }, 
  {
    path: '/info/attentionGzh',
    name: 'attentionGzh',
    component: () => import('@/views/info/attentionGzh'),
    meta: {
      title: '关注公众号'
    }
  }, 
  {
    path: '/info/product',
    name: 'product',
    component: () => import('@/views/info/product'),
    meta: {
      title: '选择产品'
    }
  }, 
]