<template>
  <div class="home">
    <div class="homeTop">
      <Nav backgroundColor="rgba(1,1,1,0)" textColor="white" />
      <div class="titleBox">
        <div class="logoBj" v-show="isShowTitle">
          <img src="../assets/logo.png" alt="" />
          &nbsp;
          <div>融安贷</div>
        </div>
        <div class="title">正规持牌助贷平台</div>
      </div>
      <div class="itemBox">
        <div class="itemContent">
          <div class="descText">您的资金需求 (元)</div>
          <div class="priceBox">
            <div>
              <span>￥</span>
              <span class="priceNum">{{ loan_money }}</span>
            </div>
            <van-button
              round
              size="small"
              color="#204afa"
              @click="onSelectPrice(200000)"
              >全部借出</van-button
            >
          </div>
          <div class="descText">参考年化利率 (单利) 7.2%-24%</div>
          <div class="priceSeleBox">
            <span
              class="priceSele"
              @click="onSelectPrice(50000)"
              :style="{
                backgroundColor: loan_money === 50000 ? 'rgb(32, 74, 250)' : '',
                color: loan_money === 50000 ? '#ffffff' : '',
              }"
            >
              50000
            </span>
            <span
              class="priceSele"
              @click="onSelectPrice(100000)"
              :style="{
                backgroundColor: loan_money === 100000 ? 'rgb(32, 74, 250)' : '',
                color: loan_money === 100000 ? '#ffffff' : '',
              }"
            >
              100000
            </span>
            <span
              class="priceSele"
              @click="onSelectPrice(150000)"
              :style="{
                backgroundColor: loan_money === 150000 ? 'rgb(32, 74, 250)' : '',
                color: loan_money === 150000 ? '#ffffff' : '',
              }"
            >
              150000
            </span>
            <span
              class="priceSele"
              @click="onSelectPrice(200000)"
              :style="{
                backgroundColor: loan_money === 200000 ? 'rgb(32, 74, 250)' : '',
                color: loan_money === 200000 ? '#ffffff' : '',
              }"
            >
              200000
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="itemBox" style="margin-top: -2.1rem">
      <div class="itemContent">
        <div class="optionItm">
          <span>借贷期限</span>
          <span>最高36期</span>
        </div>
        <div class="optionItm">
          <span>还款方式</span>
          <span>等额本息，等额本金</span>
        </div>
        <div class="optionItm">
          <span>助贷平台</span>
          <span>融安贷</span>
        </div>
        <div class="optionItm">
          <span>公司主体</span>
          <span>{{ financeConfig.companyMianName }}</span>
        </div>
      </div>
    </div>

    <div class="itemBox">
      <div class="itemContent">
        <van-field
          v-model="mobile"
          left-icon="smile-o"
          placeholder="请输入手机号"
        >
          <template #left-icon>
            <svg
              class="icon"
              style="width: 0.4rem; height: 0.4rem; color: #9e9e9e"
              aria-hidden="true"
            >
              <use xlink:href="#icon-tel"></use>
            </svg>
          </template>
        </van-field>
        <div class="errMsg" v-if="errMsg">{{ errMsg }}</div>

        <div class="immediatelyBtn">
          <van-button round block color="#204afa" @click="onSend"
            >立即借钱</van-button
          >
        </div>

        <div style="text-align: center">
          <label
            style="
              display: flex;
              margin: 30px 0px 10px 0px;
              justify-content: center;
            "
          >
            <van-checkbox
              v-model="popup_contract.status"
              :icon-size="16"
              style="margin: 0px 10px 0px 15px;  white-space: nowrap"
            >
            </van-checkbox>
            <span class="weui-agree__text" style="font-size: 12px">
              <span @click="popup_contract.status = !popup_contract.status"
                >我已阅读并同意</span
              >
              <a
                style="color: #204afa"
                @click="
                  openPopupContract(
                    true,
                    'userRegistrationServiceAgreement',
                    '用户注册服务协议'
                  )
                "
                >《注册协议》
              </a>
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="tipBox">
      <div class="tipTitle">申请流程<span>方便快捷</span>安全高效</div>
      <div class="tipItems">
        <van-row>
          <van-col span="8">
            <div
              style="
                margin: 0 auto;
                width: 1rem;
                height: 1rem;
                background-color: rgb(32, 74, 250);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <svg
                class="icon"
                style="color: #fff; width: 0.5rem; height: 0.5rem"
                aria-hidden="true"
              >
                <use xlink:href="#icon-tel"></use>
              </svg>
            </div>
            <div style="margin-top: 5px">手机注册</div>
            <div class="tipStep">第一步</div>
          </van-col>
          <van-col span="8">
            <div
              style="
                margin: 0 auto;
                width: 1rem;
                height: 1rem;
                background-color: rgb(32, 74, 250);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <svg
                class="icon"
                style="color: #fff; width: 0.5rem; height: 0.5rem"
                aria-hidden="true"
              >
                <use xlink:href="#icon-edit"></use>
              </svg>
            </div>
            <div style="margin-top: 5px">资料填写</div>
            <div class="tipStep">第二步</div>
          </van-col>
          <van-col span="8">
            <div
              style="
                margin: 0 auto;
                width: 1rem;
                height: 1rem;
                background-color: rgb(32, 74, 250);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <svg
                class="icon"
                style="color: #fff; width: 0.5rem; height: 0.5rem"
                aria-hidden="true"
              >
                <use xlink:href="#icon-moneycollect"></use>
              </svg>
            </div>
            <div style="margin-top: 5px">领取额度</div>
            <div class="tipStep">第三步</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="bottomText">
      <div style="margin-bottom: 6px">{{ financeConfig.icp }}</div>
      <div style="margin-bottom: 6px">{{ financeConfig.companyMianName }}</div>
      <div>平台仅为提供用户及第三方机构提供助贷撮合服务</div>
      <div>为了保证您的资金安全</div>
      <div>请不要相信任何要求您支付费用的信息</div>
      <div>收取营销短信时请注意仔细辨别</div>
      <div>不要轻易点击链接</div>
      <div>从手机官方应用商店等正规渠道下载APP</div>
      <div>不随意在网页搜查或点击可疑网址</div>
      <div>不通过其他APP和客服进行沟通</div>
      <div>请注意任何需要私下转账的贷款产品都是骗子</div>
      <div>联系方式 {{ financeConfig.servicePhone }}</div>
    </div>

    <!-- <div class="filingCompany">XXX小额贷款有限公司</div> -->

    <!-- 获取额度，等待验证码 -->
    <van-overlay :show="loginShow">
      <div class="loginBox">
        <div>
          <div style="text-align: right">
            <van-icon
              @click="loginShow = false"
              name="close"
              size="0.64rem"
              color="white"
            />
          </div>
          <div class="loginContent">
            <van-field
              :autofocus="autofocus"
              type="digit"
              v-model="mobile_code"
              placeholder="请输入验证码"
            >
              <template #button>
                <van-count-down
                  v-if="loginShow"
                  ref="countDown"
                  :time="time"
                  format="ss"
                >
                  <template #default="timeData">
                    <span v-if="timeData.seconds != '0'" class="block"
                      >{{ timeData.seconds }}s</span
                    >
                    <van-button
                      v-else
                      round
                      color="#204afa"
                      size="small"
                      @click="getPhoneCode"
                    >
                      重新获取
                    </van-button>
                  </template>
                </van-count-down>
              </template>
            </van-field>
            <div class="loginBtn">
              <van-button
                round
                :disabled="mobile_code ? false : true"
                block
                style="width: 4rem"
                color="#204afa"
                @click="onGetNum"
                >获取额度</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 底部弹出 -->
    <van-popup
      class="popup_contract"
      v-model:show="popup_contract.show_status"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div class="content">
        <div class="head">
          <div style="flex: 1">{{ popup_contract.title }}</div>
          <van-icon
            @click="openPopupContract(false)"
            name="cross"
            size="18"
            color="#757575"
          />
        </div>

        <van-loading
          v-if="popup_contract.loading"
          style="text-align: center"
          color="#1989fa"
        />
        <div v-else class="text" v-html="popup_contract.text"></div>
        <div class="bottom">
          <van-button
            @click="agreeContract"
            type="primary"
            color="#204afa"
            style="margin: 60px 0px 20px 0px; width: 80%"
            >同意本协议</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// 倒计时 60秒
let countNum = 1000 * 60;
import { base64_encode, base64_decode } from "@/utils/base64Util.js";
import { getFinanceConfig } from "@/config/Settings.js";
import { apiMobileCode, apiApply, apiUv } from "@/api/apiClue.js";
import { apiConfigAgreement } from "@/api/apiConfig.js";
import { isShowTitle } from "@/utils/index.js";
import Nav from "@/components/nav";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      isShowTitle: isShowTitle(),
      financeConfig: null,
      loan_money: 50000,
      mobile: "",
      mobile_code: "",
      errMsg: "",
      loginShow: false,
      autofocus: false,
      time: countNum,
      popup_contract: {
        title: "",
        text: "",
        status: false,
        show_status: false,
        loading: true,
      },
      query: null,
    };
  },
  created() {
    console.log("v20241116");
    this.financeConfig = getFinanceConfig();
    this.query = this.$route.query;
    var c_id = this.query["c_id"];
    if (c_id) {
      localStorage.setItem("c_id", c_id);
    }
    // this.mobile = this.getRandomPhoneNumber();
    // this.mobile_code = 1234;
    this.reqUv();
    // this.getContracts("userRegistrationServiceContractment", "用户注册协议");
  },
  watch: {
    loginShow(val) {
      if (val) {
        this.time = countNum;
      }
    },
  },
  methods: {
    getRandomPhoneNumber() {
      // List of possible prefixes for Chinese mobile numbers
      const prefixes = [
        "139",
        "138",
        "137",
        "136",
        "135",
        "134",
        "159",
        "158",
        "157",
        "150",
        "151",
        "152",
        "188",
        "187",
        "186",
        "185",
        "182",
        "183",
        "184",
      ];

      // Select a random prefix
      const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];

      // Generate the remaining 8 digits
      const suffix = Math.floor(Math.random() * 100000000)
        .toString()
        .padStart(8, "0");

      // Combine prefix and suffix to form the phone number
      return prefix + suffix;
    },
    // 选中金额
    onSelectPrice(val) {
      this.loan_money = val;
    },
    // 校验手机号
    validatePhoneNumber(phoneNumber) {
      // 定义手机号正则表达式
      const phoneRegex = /^1[3-9]\d{9}$/;
      // 使用test方法检查手机号是否符合格式
      if (phoneRegex.test(phoneNumber)) {
        return true;
      } else {
        this.errMsg = "请输入正确的手机号！";
        return false;
      }
    },
    // 立即借钱
    onSend() {
      this.errMsg = "";
      if (!this.mobile) {
        this.errMsg = "手机号不能为空！";
        return false;
      }
      if (!this.validatePhoneNumber(this.mobile)) {
        return false;
      }

      if (this.popup_contract.status == false) {
        this.openPopupContract(
          true,
          "userRegistrationServiceAgreement",
          "用户注册服务协议"
        );
        return false;
      }

      this.getPhoneCode();
    },
    // 获取手机号验证码
    getPhoneCode() {
      apiMobileCode({ mobile: this.mobile }).then(({ data }) => {
        if (+data.code === 1) {
          this.loginShow = true;
          setTimeout(() => {
            this.$refs.countDown.reset();
            this.autofocus = true;
          }, 100);
        } else {
          this.$toast(data.msg);
        }
      });
    },
    // 获取协议
    getContracts(key, title) {
      const _this = this;
      _this.popup_contract.loading = true;
      apiConfigAgreement({ key: key }).then(({ data }) => {
        let { stage, id } = data.data ? data.data : {};
        if (+data.code === 1) {
          var res_data = data.data;
          var details = res_data["details"];
          if (details) {
            var popup_contract = _this.popup_contract;
            popup_contract.title = title;
            popup_contract.text = base64_decode(details.value);
            popup_contract.loading = false;
            _this.popup_contract = popup_contract;
          }
        } else {
          this.$toast(data.msg);
        }
      });
    },
    // 获取额度 => 验证通过，填写资料  跳转页面
    onGetNum() {
      if (!this.mobile_code) {
        this.$toast("验证码不能为空！");
        return false;
      }
      var cid = 0;
      if (this.query["cid"]) {
        cid = this.query["cid"];
      }
      var mobile = this.mobile;
      apiApply({
        cid: cid,
        mobile: mobile,
        mobile_code: this.mobile_code,
        loan_money: this.loan_money,
      }).then(({ data }) => {
        let { stage, id } = data.data ? data.data : {};
        if (+data.code === 1) {
          // stage 0、1 继续下一步
          this.loginShow = false;
          this.mobile_code = "";
          this.$router.push({
            path: "/info/writeInfo",
            query: {
              id: data["data"]["id"],
              mobile: mobile,
              loan_money: this.loan_money,
              mobile_md: data["data"]["mobile_md"],
              cid: cid,
            },
          });
        } else {
          this.$toast(data.msg);
        }
      });
    },
    // 进入当前页面，访问一次
    reqUv() {
      // console.log(this.$route.query,'this.$route.query')
      var param = {};
      if (this.query["cid"]) {
        param["cid"] = this.query["cid"];
        apiUv(param);
      }
    },
    // 打开协议弹框
    openPopupContract(show_status, key, title) {
      var popup_contract = this.popup_contract;
      this.popup_contract.show_status = show_status;
      if (show_status) {
        popup_contract.key = key;
        popup_contract.title = title;
        this.popup_contract = popup_contract;
        this.getContracts(key, title);
      }
    },
    // 关闭协议
    closePopupContract(show_status, key = "", title = "") {},
    // 同意协议
    agreeContract() {
      var popup_contract = this.popup_contract;
      popup_contract.status = true;
      popup_contract.show_status = false;
      this.popup_contract = popup_contract;
    },
  },
};
</script>

<style scoped lang="less">
.home {
  .homeTop {
    position: relative;
    padding-bottom: 2rem;
    background: rgb(218, 230, 250);
    background: url("../assets/home.png") no-repeat;
    background-size: 100% 100%;
    .titleBox {
      display: flex;
      align-items: center;
      color: white;
      margin-left: 0.346rem;
      margin-top: 0.3rem;
      .logoBj {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.666rem;
        height: 0.906rem;
        line-height: 0.906rem;
        background: #3a61fe;
        box-shadow: inset 0px 0px 4px 1px #ffffff;
        border-radius: 0.453rem;
        font-size: 0.4rem;
      }
      img {
        width: 0.53rem;
        border-radius: 1rem;
      }
      .title {
        margin-left: 0.4rem;
        padding-left: 0.4rem;
        font-size: 0.373rem;
        border-left: 2px solid white;
      }
    }
  }

  .itemBox {
    position: relative;
    padding: 0.4rem 0.426rem 0;
    height: auto;
    .itemContent {
      padding: 0.48rem;
      background: white;
      border-radius: 0.22rem;
      box-shadow: 0px 3px 21px 1px rgba(145, 143, 143, 0.16);
      -moz-box-shadow: 0px 3px 21px 1px rgba(145, 143, 143, 0.16);
      -webkit-box-shadow: 0px 3px 21px 1px rgba(145, 143, 143, 0.16);
      .descText {
        font-size: 0.32rem;
      }
      .priceBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.346rem 0;
        .priceNum {
          font-size: 0.96rem;
          font-weight: bold;
        }
      }
      .priceSeleBox {
        display: flex;
        justify-content: space-between;
        margin-top: 0.346rem;
        .priceSele {
          display: inline-block;
          padding: 0.13rem 0.2rem;
          background: #f6f6f6;
          font-size: 0.4rem;
          border-radius: 1rem;
        }
      }

      .optionItm {
        display: flex;
        justify-content: space-between;
        line-height: 0.8rem;
        > span:first-child {
          color: #535353;
        }
      }

      .phoneIcon {
        height: 0.4rem;
        margin-top: 0.1rem;
      }
      .van-cell {
        height: 1.13rem;
        background: #f6f6f6;
        border-radius: 2rem;
      }
      .immediatelyBtn {
        margin-top: 0.42rem;
      }
    }
  }

  .tipBox {
    .tipTitle {
      margin-top: 0.853rem;
      font-size: 0.48rem;
      font-weight: bold;
      text-align: center;
      span {
        color: #3860fb;
      }
    }
    .tipItems {
      margin-top: 0.64rem;
      text-align: center;
      font-size: 0.32rem;
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
      div {
        line-height: 0.56rem;
      }
      .tipStep {
        color: #7484c9;
      }
    }
  }

  .bottomText {
    margin-top: 0.906rem;
    div {
      line-height: 0.53rem;
      font-size: 0.32rem;
      color: #999999;
      text-align: center;
    }
  }

  .filingCompany {
    margin: 1.04rem 0;
    text-align: center;
    font-size: 0.32rem;
    color: #999999;
  }

  .loginBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9rem;
    height: 100vh;
    margin-left: 0.5rem;
    .loginContent {
      width: 9rem;
      margin: 0.2rem 0;
      padding: 1rem 0.693rem 0.693rem;
      background: white;
      border-radius: 0.273rem;
      box-sizing: border-box;
      text-align: center;
      .van-cell {
        background: #f6f6f6;
        border-radius: 2rem;
        border: 1px solid #cfcfcf;
      }
      .loginBtn {
        display: flex;
        justify-content: center;
        margin-top: 0.4rem;
      }
    }
  }
  .errMsg {
    margin: 0.1rem 0.4rem;
    font-size: 0.32rem;
    color: red;
    text-align: left;
  }
}

.popup_contract {
  .content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;

    .head {
      min-height: 64px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
    }

    .text {
      padding: 20px;
      overflow-y: auto;
      padding-bottom: 120px;
    }

    .bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      background-image: linear-gradient(
        to bottom,
        #ffffff00,
        rgba(255, 255, 255, 0.5),
        white,
        white,
        white,
        white
      );
    }
  }
}

.home ::v-deep .van-field__control::placeholder{
  color: #333;
}
</style>
