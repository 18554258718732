<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive> 
  </div>
</template>

<script> 
import { isShowTitle } from "@/utils/index.js";
export default {
  components: {
    
  },
  data() {
    return {
      
    }
  },
  computed: {
   
  }, 
   
  created() { 
    isShowTitle();
  }, 
  methods: {
     
  }
}
</script>

<style lang="less">
 #app { 
  font-size: 0.373rem;
  // van-nav-bar 去掉底部白色边框
 .van-hairline--bottom:after {
    border-bottom-width: 0 !important;
  } 
 }
 
</style>
